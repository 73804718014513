import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Message from "./messages";
import "../../styles/SignUpPage.css";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import CookieService from "encrypted-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// Loading Spinner Component
const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const initialValues = {
  email: "",
  password: "",
  confirm_password: "",
  Mobile: "",
};

const validationSchema = Yup.object({
  email: Yup.string().required("Email / Mobile Number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
  Mobile: Yup.string(),
});

const RegisterForm = ({ data }) => {
  const [backendmessages, setBackendmessages] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [statusMessage, setStatusMessage] = useState(""); // Status message state
  const navigate = useNavigate();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, action) => {
      setLoading(true); // Start loading
      setStatusMessage("Please wait while we create your account..."); // Set status message
      try {
        const response = await fetch(`/api/auth/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();
        if (response.ok) {
          setBackendmessages({ success: result.message });
          CookieService.setCookie("token", result.token, 2);
          action.resetForm();
          setTimeout(() => {
            navigate("/Register");
          }, 1500);
        } else {
          setBackendmessages({ error: result.error });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Stop loading
        setStatusMessage(""); // Clear status message
      }
    },
  });

  const handleGoogleSuccess = async (credentialResponse) => {
    setLoading(true); // Start loading
    setStatusMessage("Please wait while we create your account..."); // Set status message
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    const newUser = {
      email: decoded.email,
      sign_in_with: "google",
    };

    try {
      const response = await fetch(`/api/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      const result = await response.json();

      if (response.ok) {
        setBackendmessages({ success: result.message });
        CookieService.setCookie("token", result.token, 2);
        if (result.Sign_Method === "google") {
          CookieService.setCookie("SignInMethod", result.Sign_Method);
        }
        setTimeout(() => {
          navigate("/Register");
        }, 1500);
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Stop loading
      setStatusMessage(""); // Clear status message
    }
  };

  const handlePhoneChange = (phone) => {
    setFieldValue("Mobile", phone);
  };

  const classSignup = data ? "sign-up-page-other" : "";

  return (
    <div className={`${classSignup} sign-up-page`}>
      <div className="sign-up-container">
        {data ? (
          <h2>SIGN UP</h2>
        ) : (
          <h2>
            Start Your <span>Journey</span>
          </h2>
        )}
        <Message message={backendmessages} />
        
        {/* Status message */}
        {loading && (
          <div className="status-message">
            <p>{statusMessage}</p>
          </div>
        )}
        
        <form onSubmit={handleSubmit}>
          <div className="Email-input">
            <input
              placeholder="Email / Mobile Number"
              name="email"
              value={values.email || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          {errors.email && touched.email ? <p>{errors.email}</p> : null}
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={values.password || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password ? (
            <p>{errors.password}</p>
          ) : null}

          <input
            type="password"
            placeholder="Confirm Password"
            name="confirm_password"
            value={values.confirm_password || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.confirm_password && touched.confirm_password ? (
            <p>{errors.confirm_password}</p>
          ) : null}

          {loading ? (
            <LoadingSpinner />
          ) : data ? (
            <button type="submit" className="sign-up-button line-shade">
              SIGN UP
            </button>
          ) : (
            <button type="submit" className="sign-up-button">
              Register Free
            </button>
          )}

          <p className="addictional-text">
            By clicking register free, I agree to the {" "}
            <Link to="/terms-and-conditions">T&C</Link> and {" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </form>
        {data ? (
          <p className="signin-text">
            Already have an account?
            <a href="/signin"> Log In</a>
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="google-signup">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            console.log("Login Failed");
          }}
          useOneTap={false}
          auto_select={false}
          prompt="select_account"
          text="Sign up with Google"
        />
      </div>
    </div>
  );
};

export default RegisterForm;
