import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Message from "./messages";
import * as Yup from "yup";
import "../../styles/LoginPage.css";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import CookieService from "encrypted-cookie";
import { UserContext } from "../../context/UserContext";

const backenduRL = process.env.REACT_APP_API_UR;

const emailPasswordValidationSchema = Yup.object({
  email: Yup.string().required("Email is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

const mobileNumberValidationSchema = Yup.object({
  mobile: Yup.string().required("Mobile number is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

const LoginPage = () => {
  const { setUserDetails } = useContext(UserContext);
  const [backendmessages, setBackendmessages] = useState(null);
  const navigate = useNavigate();
  const { userImages, setUserImages } = useContext(UserContext);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: emailPasswordValidationSchema,
    onSubmit: async (values, action) => {
      console.log("Submitting form:", values);

      try {
        const response = await fetch(`/api/auth/signin`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        const result = await response.json();

        if (response.ok) {
          CookieService.setCookie("token", result.token, 2);
          setBackendmessages({ success: result.message });
          action.resetForm();
          setUserDetails(result.existingUserAgg);
          localStorage.setItem("personal", JSON.stringify(result.existingUserAgg));
          localStorage.setItem("personalDetails", "true");

          if (result.images) {
            localStorage.setItem("userimages", JSON.stringify(result.images));
            setUserImages((prevImages) => [...prevImages, ...result.images]);
          }

          if (!result.existingUserAgg) {
            navigate("/Register");
          } else {
            if (result.existingUserAgg.membership_status === "Paid") {
              setTimeout(() => {
                navigate("/dashboard");
              }, 1500);
              return;
            }
            setTimeout(() => {
              navigate("/subscribe-plan");
            }, 1500);
          }
        } else {
          setBackendmessages({ error: result.error });
        }
      } catch (error) {
        console.error("Error:", error);
        setBackendmessages({
          error: "Something went wrong. Please try again.",
        });
      }
    },
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formik;

  const handleForgot = () => {
    console.log("Forgot password clicked");
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const token = credentialResponse.credential;
    const decoded = jwtDecode(token);
    const newUser = {
      email: decoded.email,
      Name: decoded.name,
      sign_in_with: "google",
    };

    try {
      const response = await fetch(`/api/auth/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUser),
      });

      const result = await response.json();

      if (response.ok) {
        CookieService.setCookie("SignInMethod", result.sign_in_with);
        CookieService.setCookie("token", result.token, 2);
        setBackendmessages({ success: result.message });
        localStorage.setItem("personal", JSON.stringify(result.existingUserAgg));
        localStorage.setItem("personalDetails", "true");

        if (result.images) {
          localStorage.setItem("userimages", JSON.stringify(result.images));
          setUserImages((prevImages) => [...prevImages, ...result.images]);
        }

        if (!result.existingUserAgg) {
          navigate("/Register");
        } else {
          setTimeout(() => {
            navigate("/subscribe-plan");
          }, 1500);
        }
      } else {
        setBackendmessages({ error: result.error });
      }
    } catch (error) {
      console.error("Error:", error);
      setBackendmessages({
        error: "Something went wrong. Please try again.",
      });
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>
          <span>Welcome back!</span> Please Login
        </h2>
        <Message message={backendmessages} />
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Email ID / Mobile Number"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && <p className="error">{errors.email}</p>}

          <input
            type="password"
            placeholder="Enter Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.password && touched.password && <p className="error">{errors.password}</p>}

          <a
            href="/forgot-password"
            className="forgot-password"
            onClick={handleForgot}
          >
            Forgot your password?
          </a>

          <button type="submit" className="login-button">
            Login
          </button>

          <p className="or-button">OR</p>

          <div className="google-signup">
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={() => {
                console.log("Login Failed");
              }}
              useOneTap={false}
              auto_select={false}
              prompt="select_account"
              text="Sign up with Google"
            />
          </div>

          <p className="create-acc">
            Don’t have an account?
            <a href="/signup">Create an account</a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
